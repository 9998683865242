import axios from "axios"
import cookies from "vue-cookies"

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = 'https://zytp23.haiyiban.top/api/'
} else {
    axios.defaults.baseURL = 'https://zytp23.haiyiban.top/api/'
}

// 发送请求之前做特殊处理
axios.interceptors.request.use(
    config => {
        let token = cookies.get('token')
        if(token){
            config.headers['Token'] = token
        }
        config.headers['Device'] = 'mobile'
        return config
    }
)

// 返回结果前做特殊处理
axios.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        if(error.message=='Request failed with status code 401'&&error.response.data.msg=='请登录后操作'){
            cookies.remove('userinfo')
        }
        return Promise.reject(error)
    }
)

const get = (url, data) => {
    return axios({
        method: 'get',
        url: url,
        data: data,
    })
}

const post = (url, data) => {
    return axios({
        method: 'post',
        url: url,
        data: data,
    })
}

const ajax = {
    get: get,
    post: post,
}

export default ajax