var wx = require('weixin-js-sdk')

const mixin = {
    data: function () {
        return {}
    },
    methods: {
        onClickLeft() {
            if (window.history.length > 1) {
                this.$router.back()
            } else {
                this.$router.replace('/')
            }
        },
        checkLoginThen: function () {
            return new Promise((resolve, reject) => {
                let token = this.$cookie.get('token')
                if (token) {
                    resolve()
                } else {
                    reject()
                }
            })
        },
        setJssdkConfig: function () {
            let url = window.location.href
            this.$http.getJssdkConfig({ redirect_url: url })
                .then(res => {
                    if (res.code == 1) {
                        let jssdkConfig = res.data
                        wx.config({
                            debug: jssdkConfig.debug,
                            appId: jssdkConfig.appId,
                            timestamp: jssdkConfig.timestamp,
                            nonceStr: jssdkConfig.nonceStr,
                            signature: jssdkConfig.signature,
                            jsApiList: jssdkConfig.jsApiList
                        });

                        wx.ready(function () {

                            wx.updateAppMessageShareData({
                                title: '快来投票！2024年海南省第四届中医药经典知识竞赛活动·作品展播',
                                desc: '动动手指，为你心仪的作品投票',
                                link: jssdkConfig.url,
                                // imgUrl: 'https://zytp23.haiyiban.top/uploads/20221027/bg.png',
                                imgUrl: 'https://danzhoutongxing.oss-cn-hangzhou.aliyuncs.com/uploads/20241007/a2fec9ef-dee3-4869-95c5-d66254dcff3d.png',
                                success: function () { },
                            })
                            wx.updateTimelineShareData({
                                title: '快来投票！2024年海南省第四届中医药经典知识竞赛活动·作品展播',
                                link: jssdkConfig.url,
                                // imgUrl: 'https://zytp23.haiyiban.top/uploads/20221027/bg.png',
                                imgUrl: 'https://danzhoutongxing.oss-cn-hangzhou.aliyuncs.com/uploads/20241007/a2fec9ef-dee3-4869-95c5-d66254dcff3d.png',
                                success: function () { }
                            })
                        })
                    }
                })
                .catch(err => {

                })
        }
    }
}

export default mixin