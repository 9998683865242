<template>
  <div class="page-container">
    <img class="top-bg" src="@/assets/bg.png" alt="" />
    <div class="main-content">
      <div class="section-1 tongji">
        <div class="flex">
          <div class="tongji-item">
            <div class="num">{{ danweinum }}</div>
            <div class="text">参选单位</div>
          </div>
          <div class="su"></div>
          <div class="tongji-item">
            <div class="num">{{ votenum }}</div>
            <div class="text">累计投票</div>
          </div>
          <div class="su"></div>
          <div class="tongji-item">
            <div class="num">{{ viewnum }}</div>
            <div class="text">访问次数</div>
          </div>
        </div>
        <!-- <div class="djs" v-if="time > 0">
          投票时间还剩
          <van-count-down :time="time" class="djs-section">
            <template #default="timeData">
              <span class="num">{{ timeData.days }}</span>
              <span class="colon">天</span>
              <span class="num">{{ timeData.hours }}</span>
              <span class="colon">小时</span>
              <span class="num">{{ timeData.minutes }}</span>
              <span class="colon">分</span>
              <span class="num">{{ timeData.seconds }}</span>
              <span class="colon">秒</span>
            </template>
          </van-count-down>
        </div>
        <div class="end-tips" v-else>活动投票时间已截止</div> -->
      </div>
      <div class="section-1 description">
        <div class="title">活动介绍</div>
        <div class="texts">
          为夯实中医药人才基础知识，增强我省中医药专业技术人员的中医理论功底和经典运用能力，提升中医药人员的经典理论与临床运用水平，展现海南自贸港热带中医及民族医药特色。由海南省卫生健康委员会、海南省中医药管理局决定举办2024年海南省第四届中医药经典知识竞赛作品展播活动。
        </div>
        <div class="texts">活动主题：医者争锋 弘扬经典</div>
        <div class="texts">活动时间：</div>
        <div class="texts">1、竞赛作品线上投票时间</div>
        <div class="texts">2、投票时间：10月8日8:00至10月12日24:00</div>
        <div class="texts">投票规则：</div>
        <div class="texts">1、每位用户每天可以投3票。</div>
        <div class="texts">2、此次投票结果作为选拔成绩的50%计入最终结果。</div>
        <div class="texts">3、主办方还将组织专家对报送作品进行选拔，专家评价结果作为选拔成绩的50%计入最终结果。</div>
        <!-- <div class="texts">
          3、主办方还将组织专家对报送作品进行选拔，专家评价结果作为选拔成绩的60%计入最终结果。
        </div> -->
      </div>
      <div class="section-1 search">
        <input type="text" placeholder="输入作品名称" v-model="keyword" />
        <img class="icon" src="@/assets/search.png" alt="" @click="onSerachClick" />
      </div>
      <div class="index-type">
        <div class="title">-投票活动入口-</div>
        <div class="type-item grp1" @click="onCategoryClick(1)">
          <div class="text">中医经典传承之星</div><img class="more" src="@/assets/toright.png" />
        </div>
        <div class="type-item grp2" @click="onCategoryClick(2)">
          <div class="text">中医经典运用之星</div><img class="more" src="@/assets/toright.png" />
        </div>
        <div class="type-item grp3" @click="onCategoryClick(3)">
          <div class="text">中医经典科普之星</div><img class="more" src="@/assets/toright.png" />
        </div>
      </div>
      <div class="footer">
        <div class="title">主办单位</div>
        <div class="line">海南省卫生健康委员会</div>
        <div class="line">海南省中医药管理局</div>
        <!-- <div class="title">承办单位</div>
        <div class="line">海南医学院</div>
        <div class="title">协办单位</div>
        <div class="line">海南广播电视总台公共频道</div>
        <div class="line">海南省中医院</div>
        <div class="line">海南省健康宣传教育中心</div>
        <div class="line">海南出版社</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import commonMixin from '@/mixin/common'
export default {
  name: "home",
  mixins: [commonMixin],
  data() {
    return {
      webname: "海南省中医特色化大比武",
      time: 0,
      danweinum: "",
      viewnum: "",
      votenum: "",
      keyword: "",
    };
  },
  created() {
    if (!this.$utils.isWeixinClient()) {
      this._isWeixinCilent = false
    } else {
      this._isWeixinCilent = true
      this.setJssdkConfig({redirect_url:window.location.href})
    }
  },
  activated() {
    this.getDashboard()
  },
  methods: {
    onSerachClick() {
      if (this.keyword) {
        this.$router.push('/entrylist?keyword=' + this.keyword)
      }
    },
    onCategoryClick(category) {
      this.$router.push('/entrylist?category=' + category)
    },
    getDashboard() {
      this.$http.dashboard({})
        .then(res => {
          if (res.code == 1) {
            this.danweinum = res.data.total_danwei
            this.votenum = res.data.total_vote
            this.viewnum = res.data.total_view
            this.time = res.data.seconds > 0 ? res.data.seconds * 1000 : 0
          }
        })
        .catch(err => {

        })
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  .top-bg {
    width: 100%;
    display: block;
  }

  .main-content {
    background-image: url(@/assets/bg2.png);
    background-size: 100%;
    background-repeat: repeat-y;

    .section-1 {
      background: #feffff;
      box-shadow: 0px 4px 25px 0px rgba(204, 204, 204, 0.44);
      border-radius: 10px;
    }

    .tongji {
      box-sizing: border-box;
      padding: 22px 15px;
      margin: 0 15px;
      transform: translateY(-15px);

      .flex {
        display: flex;
        justify-content: space-between;
      }

      .su {
        width: 1px;
        height: 56px;
        background: #d7d8d9;
      }

      .tongji-item {
        width: 30%;
        text-align: center;

        .num {
          font-size: 17px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #fd4c34;
          margin-bottom: 15px;
        }

        .text {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #6a6d74;
        }
      }

      .end-tips {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #cccccc;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 38px;
      }

      .djs {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 38px;

        .djs-section {
          background-color: #fff4f3;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px 11px;
          margin-left: 10px;

          .num {
            font-weight: 500;
            color: #fd4c34;
            text-align: center;
            display: block;
            padding: 0 3px;
          }

          .colon {
            padding: 0 3px;
          }
        }
      }
    }

    .description {
      padding: 22px 15px;
      margin: 0 15px;

      .title {
        font-size: 17px;
        height: 32px;
        font-weight: 500;
        color: #333333;
      }

      .texts {
        font-size: 14px;
        color: #6a6d74;
        text-align: justify;
        text-align-last: left;
        line-height: 22px;
      }
    }

    .search {
      padding: 10px 36px 10px 17px;
      margin: 15px;
      position: relative;

      input {
        border: none;
        height: 18px;
        line-height: 18px;
        width: 100%;
        font-size: 14px;
        display: block;

        &::placeholder {
          color: #6a6d74;
        }
      }

      .icon {
        width: 16px;
        height: 16px;
        display: block;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        position: absolute;
      }
    }

    .index-type {
      .title {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #9D6B45;
        text-align: center;
        margin: 30px 0 15px 0;
      }

      .type-item {
        position: relative;
        margin: 15px 15px 0 15px;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        &::before {
          content: "";
          display: block;
          padding-top: 22%;
        }

        &.grp1 {
          background-image: url(@/assets/grp1.png);
        }

        &.grp2 {
          background-image: url(@/assets/grp2.png);
        }

        &.grp3 {
          background-image: url(@/assets/grp3.png);
        }

        &.grp4 {
          background-image: url(@/assets/grp4.png);
        }

        .text {
          position: absolute;
          left: 15px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 18px;
          line-height: 20px;
          height: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FEFFFF;
        }

        .more {
          width: 22px;
          height: 22px;
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          display: block;
        }
      }
    }

    .footer {
      color: #65594e;
      font-size: 14px;
      text-align: center;
      padding: 40px;

      .title {
        font-size: 16px;
        line-height: 40px;
        height: 32px;
        font-weight: 500;
        color: #333333;
      }

      .line {
        font-size: 13px;
        line-height: 22px;
      }
    }
  }
}
</style>
