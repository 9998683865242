import ajax from "./ajax"

const http = {
    index: (formdata) => {
        return ajax.post('/', formdata)
    },
    vote: (formdata) => {
        return ajax.post('/index/vote', formdata)
    },
    detail: (formdata) => {
        return ajax.post('/index/detail', formdata)
    },
    dashboard: (formdata) => {
        return ajax.post('/index/dashboard', formdata)
    },
    getJssdkConfig: (formdata) => {
        return ajax.post('/wxgzh/jssdk', formdata)
    },
    login: {
        logout: (formdata) => {
            return ajax.post('/user/logout', formdata)
        },
    },
    user: {
        index: (formdata) => {
            return ajax.post('/user/index', formdata)
        }
    },
}

export default http